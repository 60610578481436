//
// Timeline
//

// Base
.timeline {
 
  // Item
  .timeline-item {
    position: relative;
    padding: 0;
    padding-left: 20px;
    margin: 0;
    display: flex;
    align-items: center;

    &.timeline-item-current:before {
        background-color: white !important;
      }

    &:before {
      content: '';
      position: absolute;
      // left: $label-width + 1px;
      left: 0;
      width: 3px;
      top: 0;
      bottom: 0;
      background-color: gray;
    }
    &:last-child {
      .timeline-line {
        bottom: 100%;
      }
    }
  }

  // Line
  .timeline-line {
    display: block;
    content: ' ';
    justify-content: center;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transform: translate(50%);
    border-left-width: 1px;
    border-left-style: dashed;
    border-left-color: $gray-300;
  }

  // Icon
  .timeline-icon {
    z-index: 1;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  // Content
  .timeline-content {
    width: 100%;
    overflow: auto;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // Vertical center
  &.timeline-center {
    .timeline-item {
      align-items: center;

      &:first-child {
        .timeline-line {
          top: 50%;
        }
      }

      &:last-child {
        .timeline-line {
          bottom: 50%;
        }
      }
    }
  }
}
//
// Timeline Label
//

.timeline-label {
  position: relative;

  $label-width: 50px;

  

  //
  .timeline-item {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 1.7rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .timeline-label {
    width: $label-width;
    flex-shrink: 0;
    position: relative;
    color: $gray-800;
  }

  .timeline-badge {
    flex-shrink: 0;
    background: $body-bg;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    margin-top: 1px;
    margin-left: -0.5rem;
    padding: 3px !important;
    border: 6px solid $body-bg !important;

    span {
      display: block;
      border-radius: 100%;
      width: 6px;
      height: 6px;
      background-color: $gray-200;
    }
  }

  .timeline-content {
    flex-grow: 1;
  }
}
